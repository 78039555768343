import { useState } from 'react';
import Card from 'components/card';
import InputField from 'components/fields/InputField';
import api from '../../api.js';
import { useAuth } from 'contexts/AuthContext';
import Swal from 'sweetalert2';
import { Spinner } from '@chakra-ui/spinner';

const Setup = () => {
  const auth = useAuth();
  const [buttonloading, setButtonLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    const form = new FormData(e.target);
    const name = form.get('name');
    const email_suffix = form.get('email_suffix');
    const website = form.get('website');
    const id = auth.user.id;
    api.post('/setup', { id, name, email_suffix, website }).then(({ data }) => {
      setButtonLoading(false);
      if (data.status === 'success') {
        Swal.fire({
          title: 'Success!',
          text: `You have finished onboarding, you will now be redirected to the dashboard.`,
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          window.location.href = '/dashboard';
        });
      } else if (data.status === 'error') {
        Swal.fire({
          title: 'Error!',
          text: `Error while setting up: ${data.message}`,
          icon: 'error',
          confirmButtonText: 'OK',
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: `Error while setting up, please try again.`,
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    });
  };
  return (
    <div className="mt-3 h-full w-full">
      <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3  3xl:w-7/12">
        <Card extra={'h-full mx-auto pb-3'}>
          <form onSubmit={handleSubmit}>
            <div className="rounded-[20px]">
              <div className="h-full w-full rounded-md px-3 pt-7 md:px-8">
                {/* Header */}
                <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
                  Organization Info
                </h4>
                <div className="mt-7 grid grid-cols-1 gap-3 md:grid-cols-2">
                  <InputField
                    extra="mb-3"
                    label="Organization Name"
                    placeholder="Example Name"
                    id="name"
                    field_name="name"
                    type="text"
                  />
                  <InputField
                    extra="mb-3"
                    label="Email Suffix"
                    placeholder="@example.org"
                    id="email_suffix"
                    field_name="email_suffix"
                    type="text"
                  />
                  <InputField
                    extra="mb-3"
                    label="Organization Website"
                    placeholder="https://www.example.org"
                    id="website"
                    field_name="website"
                    type="url"
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 flex flex-col justify-between px-3 pt-2 md:flex-row md:px-8">
              {buttonloading ? (
                <button
                  className="linear cursor-pointer rounded-md bg-brand-900 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
                  disabled
                >
                  <Spinner
                    size="xl"
                    thickness="2px"
                    style={{ height: 14, width: 14 }}
                  />
                </button>
              ) : (
                <button className="linear cursor-pointer rounded-md bg-brand-900 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2">
                  Submit
                </button>
              )}
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
};

export default Setup;
